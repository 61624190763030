import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const TermsPage = () => (
  <Layout page="booking-conditions">

    <Helmet
      title="Terms and Conditions"
      meta={[
        {
          name: 'description', content: 'Terms and Conditions for travelling with Haka Tours.',
        },
        { name: 'og:title', content: 'Booking terms and conditions' },
        {
          name: 'og:description', content: 'Terms and Conditions for travelling with Haka Tours.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/booking-conditions/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/footer-bg.jpg?auto=format&fit=crop">
      <h1 className="c-heading c-heading--h1">Booking terms and conditions</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container">
        <p>
          The Booking Conditions you agree to when you make your booking apply to your trip.
          If you are rebooking a trip with a credit voucher you will need to agree to the most current version of the Booking Conditions that apply when you rebook.
        </p>
        <p>Please take the time to read and understand the conditions of booking set out below prior to booking a trip with us. </p>
        <p>In these booking terms and conditions, the terms “<b>Haka Tours</b>”, <b>“Company”</b>, “<b>we</b>”, “<b>us</b>” and “<b>our</b>” are references to Haka Tours Limited (company number 3478409).</p>
        <br />
        <h3>1. Our Contract</h3>
        <p>Your contract is with Haka Tours. By booking a trip with us,
          you agree to be bound by these Booking Terms and Conditions and any special conditions that may apply to your trip
          (which constitutes the entire agreement between you and us).
        </p>
        <br />
        <h3>2. Privacy Policy</h3>
        <p>
          Any personal information that we collect about you will be handled in accordance with our Privacy Policy
          [IL2] and may be used for any purpose associated with the operation of your tour.
          In making this booking you consent to this information being passed on to the relevant persons such as our agents,
          service providers or other suppliers to enable us to operate the trip or, if permitted by any relevant Spam laws,
          to send you marketing material in relation to our events and special offers.
        </p>
        <p>You are responsible for reviewing this Privacy Policy periodically and informing yourself of any changes to this <a href="/privacy-policy/" target="_blank">Privacy Policy.</a></p>
        <br />
        <h3>3. Price Quote</h3>
        <p>Your booking will be accepted by us on this basis. The services to be provided are those referred to in your booking confirmation invoice.</p>
        <p>
          Prices quoted are per person in New Zealand Dollars and subject to availability.
          We reserve the right to amend pricing errors or omissions at any time until your deposit is paid in full.
          Once we receive your booking request and deposit, we will confirm your booking within 10 business days.
        </p>
        <br />
        <h3>4. Deposits and Final Payments</h3>
        <p>
          To confirm a booking, you will be required to pay a $99 non-refundable deposit.
          If the booking is within 60 days of travel, we require full payment to confirm your booking.
          No accommodation confirmations or arrangements will be made until payment is received.
          Final payment is due 60 days prior to the tour start date.
        </p>
        <p>
          Prices are valid for payment by credit card (Visa or Mastercard) or bank deposit.
          If you are paying by credit card, a 2% credit card fee applies, or a $25 NZD bank fee if paying by overseas bank.
          Fees are in addition to the tour amount due and must be taken into account, along with fees incurred by your own bank.
          The amount received by Haka Tours must be the correct amount invoiced for in New Zealand dollars.
          Haka Tours is not responsible for bank fees that have not been taken into account or loss due to exchange rate.
          Any shortfall of payment due to exchange rates or bank fees is the responsibility of the tour guest or group.
          Final documents will not be issued until full payment is made and cleared funds are received.
        </p>
        <br />
        <h3>5. Cancellation by you</h3>
        <p>If you cancel some or all portions of your booking the cancellation terms set out below will apply. A cancellation will only be effective when we receive written confirmation of the cancellation. If you cancel your tour:</p>
        <p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>56 days or more prior to departure, we will hold your deposit amount as a credit;</li>
            <li>between 31 and 55 days prior to departure, we charge a cancellation fee of 30% of the total booking cost;</li>
            <li>between 30 and 15 days prior to departure, we charge a cancellation fee of 60% of the total booking cost; or</li>
            <li>14 days or fewer prior to departure, we charge a cancellation fee of 100% of the booking cost.</li>
          </ul>
        </p>
        <p>
          You are strongly advised to take out cancellation insurance at the time of booking.
          If you leave a trip for any reason after it has commenced, we are not obliged to make any refunds for unused services.
          If you fail to join a trip, join it after departure, or leave it prior to its completion, no refund will be made.
          The above cancellation terms are in addition to fees which may be levied by accommodation providers,
          travel agents or third-party tour and transport operator fees. Please refer to clause 19 (Liability),
          which provides more information about your rights under New Zealand Consumer Law which may apply to your cancellation.
        </p>
        <br />
        <h3>6. Cancellation by us</h3>
        <p>
          Haka Tours reserves the right to make changes to our itineraries or cancel any trip, including a guaranteed trip,
          at any time prior to departure if something beyond the control of Haka Tours deems it necessary;
          including natural disasters, political instability, terrorism, weather, cultural conditions or other external events.
        </p>
        <p>In the event that we cancel your tour, you can transfer amounts paid to an alternate booking date or receive a refund, unless your booking is cancelled due to a Force Majeure Event (as defined below).</p>
        <p><b>Cancellation due to Force Majeure</b></p>
        <p>If your tour is cancelled due to a Force Majeure Event, we can offer you a choice of:</p>
        <p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>a 100% credit of monies paid for your tour; or</li>
            <li>a refund minus unrecoverable costs.</li>
          </ul>
        </p>
        <p>
          If the cancellation due to a Force Majeure Event occurs after your tour has commenced, we can offer you a choice of a pro-rata:
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>100% credit for the days that remain on your tour; or</li>
            <li>refund minus unrecoverable costs of the days that remain on your tour.</li>
          </ul>
        </p>
        <p>
          A “Force Majeure Event” includes but is not limited to: acts of God; war; civil commotion; riot; blockade or embargo;
          fire; explosion; breakdown; union dispute; earthquake; epidemic, pandemic or other health emergency; flood;
          windstorm or other extreme weather event; lack or failure of courses of supply; passage of any law, order, proclamation,
          regulation, ordinance, demand, requisition or requirement or any other act of any government authority,
          beyond the reasonable control of the parties, whether or not foreseeable,
          which would make it dangerous or not viable for your tour to commence or continue.
        </p>
        <p>In the event of any cancellation, there will be no claim for damages by either party against the other and we are not responsible for any incidental expenses that you may have incurred.</p>
        <p>
          If an activity, service or accommodation booking is cancelled by the provider (for example due to weather)
          Haka Tours may refund you or offer a suitable alternative.
          Refunds will not be processed until after Haka Tours have received a refund from the provider.
          Any refunds will be returned on the same credit card or bank account to the same person(s) and in the same proportions as the original transaction.
        </p>
        <p><b>Important Note regarding credit:</b></p>
        <p>
          There may be circumstances in which we issue you with credit. For instance,
          we may offer you the choice of a credit voucher instead of a cash refund when you are entitled to a full or partial refund.
          Any credit issued under these Booking Conditions:
        </p>
        <p>
          <ul>
            <li>does not have an expiry date;</li>
            <li>may be applied towards any other available trip offered by us;</li>
            <li>is not transferrable to another person or redeemable for cash; and</li>
            <li>may not be used to book flights or insurance as they will have their own booking conditions.</li>
          </ul>
        </p>
        <br />
        <h3>7. Booking Amendments</h3>
        <p>
          <b>7.1</b> If you amend your booking within two months prior to arrival will incur a $150 admin fee and any additional fees charged by any third party suppliers.
        </p>
        <p>
          <b>7.2</b> If you need to change to an alternative departure date you may do so in accordance with the below fee table.
          Please note changes to tour, departure date, activities or additional accommodation is not permitted within 56 days of departure
        </p>
        <p>
          <b>7.3</b> If you need to change to an alternative departure date you may do so in accordance with the below fee table:
        </p>
        <br />
        <table>
          <tr>
            <th>Period of notice</th>
            <th>Transfer Fee</th>
          </tr>
          <tr>
            <td>Outside 56 days</td>
            <td>No fee</td>
          </tr>
          <tr>
            <td>8 - 56 days</td>
            <td>$500 Admin Fee</td>
          </tr>
          <tr>
            <td>Within 7 days</td>
            <td>100% of tour cost</td>
          </tr>
        </table>
        <p>
          <b>7.4 </b> If the tour you are changing to has a higher price you are liable to cover the difference.
        </p>
        <br />
        <h3>8. Inclusions</h3>
        <p>The land price of your trip includes as outlined on each tour itinerary:</p>
        <p>
          <ul>
            <li>all accommodation</li>
            <li>all transport</li>
            <li>all breakfasts and some meals</li>
            <li>the services of a Haka Guide</li>
          </ul>
        </p>
        <br />
        <h3>9. Exclusions</h3>
        <p>The land price of your trip does not include:</p>
        <p>
          <ul>
            <li>international or internal flights unless specified</li>
            <li>airport transfers, taxes and excess baggage charges unless specified</li>
            <li>meals other than those specified</li>
            <li>visa and passport fees</li>
            <li>travel insurance</li>
            <li>optional activities and all personal expenses.</li>
          </ul>
        </p>
        <br />
        <h3>10. Age and Health Requirements</h3>
        <p><b>Minimum Age:</b></p>
        <p>
          Haka Tours offers tours across NZ that are designed for travellers that are young at heart and ready for an adventure.
          Due to the vast majority of accommodations we use, we cannot accept anyone who is under the age of 18 on our tours unless at the discretion of a representative of Haka Tours.
        </p>
        <p><b>Maximum Age:</b></p>
        <p>
          We have no upper age limit though we remind you that our trips can be physically demanding and passengers must ensure that they are suitably fit to allow full participation.
          We can provide details on mandatory health requirements; however, we are not medical experts.
          It is your responsibility to ensure that you obtain proper and detailed medical advice at least two months prior to travel for the latest health requirements and recommendations for your trip to NZ.
          If you have any concerns surrounding your health on our tours please contact us and we will be more than happy to discuss your options.
        </p>
        <br />
        <h3>11. New Zealand Driving Laws</h3>
        <p>
          Our drivers and guides are required to take adequate breaks in between driving.
          The maximum time that a driver can drive in any one day is 13 hours.
          Once the driver has stopped, they must have no shorter than a 10-hour break.
          There also must be at least one 24-hour break in 7 days.
        </p>
        <br />
        <h3>12. Travel Insurance</h3>
        <p>
          Travel insurance is mandatory for all our travellers and must be taken out at the time of booking.
          Your travel insurance must provide cover against personal accident, death, medical expenses and emergency repatriation with a
          recommended minimum coverage of US$200,000 for each of the categories of cover. We also strongly recommend it covers cancellation,
          curtailment, personal liability and loss of luggage and personal effects. You must provide your travel insurance policy number
          and the insurance company’s 24-hour emergency contact number on the first day of your trip; you will not be able to join the
          trip without these details. If you have travel insurance connected to your credit card or bank account please ensure you have
          details of the participating insurer, the insurance policy number and emergency contact number with you rather than the bank’s name
          and credit card details.
        </p>
        <br />
        <h3>13. Visas, Passports and Travel Documents</h3>
        <p>
          It is your responsibility to ensure that you meet the requirements of immigration and other government authorities by ensuring that you have a valid passport,
          visa and any permits needed to enter New Zealand. Any losses or expenditures incurred as a result of a failure to meet immigration and government requirements is your responsibility.
        </p>
        <br />
        <h3>14. Change of itinerary</h3>
        <p>
          You appreciate and acknowledge that the nature of this type of travel requires considerable flexibility and you should allow for alternatives.
          The itinerary provided for each trip is representative of the types of activities contemplated, but it is understood that the route, schedules,
          itineraries, amenities and mode of transport may be subject to alteration without prior notice due to local circumstances or events.
        </p>
        <p>While we endeavour to operate all trips as described we reserve the right to change the trip itinerary. Please refer to our website before departure for the most recent updates to your itinerary. </p>
        <p>
          <b>Before departure: </b>
          If we make a major change we will inform you as soon as reasonably possible if there is time before departure.
          The definition of a major change is deemed to be a change affecting at least one day in five of the itinerary.
          When a major change is made you may choose between accepting the change,
          obtaining a refund of money paid on the land portion of the trip only or accepting an alternative trip offered.
        </p>
        <p>
          <b>After departure: </b>
          We reserve the right to change an itinerary after departure due to local circumstances or a Force Majeure Event.
          In such emergency circumstances, the additional cost of any necessary itinerary alterations will be covered by you.
        </p>
        <p>Please note we are not responsible for any incidental expenses that may be incurred as a result of any change in itineraries including but not limited to visas, vaccinations or non-refundable or non-changeable flights.</p>
        <br />
        <h3>15. Authority on tour</h3>
        <p>Our trips are run by a tour guide. During your tour, all decisions made by our tour guides or activity suppliers in relation to the operational requirements of the tour and in relation to your health and safety will be final.</p>
        <p>By travelling with Haka Tours, you agree to abide by the authority of the tour guide or optional activity supplier.</p>
        <p>
          Should you fail to comply with the above or commit any illegal act when on the tour or,
          if in the opinion of the Tour manager (in their sole discretion), your behaviour is abusive and is causing or is likely to cause danger,
          distress or material annoyance to others, Haka Tours will terminate your travel arrangements immediately without any liability on our part or on the part of any third party agencies.
          You will not be entitled to any refund for unused or missed services or costs incurred resulting from the termination of the travel arrangements,
          including, without limitation, return travel, accommodations, meals, and/or incidentals.
          We may also elect not to carry you on any future trips booked. You must at all times comply with the laws, customs,
          foreign exchange and drug regulations of New Zealand.
        </p>
        <p>
          Tour guests and group leaders agree that they are responsible for any costs incurred by Haka Tours or Haka Tour’s suppliers as a result of damage,
          destruction, theft, or excess cleaning fees related to the guest’s accommodation, transport, or other use of facilities while on Tour.
        </p>
        <br />
        <h3>16. Acceptance of risk</h3>
        <p>
          You acknowledge that the nature of the trip may be adventurous and participation involves a degree of personal risk.
          You may be visiting places where the political, cultural and geographical attributes present dangers and physical challenges greater than those present in our daily lives.
        </p>
        <p>
          You acknowledge that you are choosing to travel at a time where you may be exposed to the COVID-19 virus.
          We will take all reasonable steps to ensure your safety and may require you to follow additional safety protocols on your trip.
        </p>
        <p>
          We use information from the NZ government and transport agency as well as reports from our own contacts in assessing whether the itinerary should operate.
          However, it is also your own responsibility to acquaint yourself with all relevant travel information,
          including applicable health risks and the nature of your itinerary.
          You acknowledge that your decision to travel is made in light of consideration of this information and you accept that you are aware of the personal risks attendant upon such travel.
          To the fullest extent permitted by law, we accept no liability in relation to these additional risks.
        </p>
        <br />
        <h3>17. Liability and Service Guarantee</h3>
        <p>Our services which include our tour services (“<b>Services</b>”) may come with guarantees that cannot be excluded under Fair Trading Act 1986.</p>
        <p>
          <b>17.1</b> To the maximum extent permitted by law, we exclude all liability whatsoever to you or any other person
          (whether in contract, tort or otherwise) for any loss (whether direct, indirect, consequential)
          including death or personal injury or damage of any kind that may be suffered as a result of any act or
          omission whether negligent or otherwise by or on behalf of us in connection with the Services or any other matter or
          thing relating to these Booking Terms and Conditions except to the extent that such loss or damage is incurred as a direct result
          of our fraud or wilful misconduct. This clause does not limit or exclude your rights under the Fair Trading Act.
        </p>
        <p>
          <b>17.2</b> (Limitation of Liability) Where the law implies a warranty into these Booking Terms and Conditions which may not lawfully be excluded
          (in particular warranties under the Fair Trading Act) our liability for breach of such a warranty will be limited to either supplying the
          Services again or payment of the cost of having the services supplied again.
        </p>
        <p>
          <b>17.3</b> (Indemnity) You indemnify us (and all of our subsidiaries, officers, employees, contractors and agents)
          against all losses, claims actions, proceedings, damages, costs and expenses (including legal fees) arising from
          any claim by a third party arising directly or indirectly out of or in connection with:
        </p>
        <p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>your access or use of the Services, this includes your delegate’s access or use of the Services; and</li>
            <li>
              any breach by you (or your delegate) of:
              <ul style={{ listStyleType: 'upper-roman' }}>
                <li>these Booking Terms and Conditions; or</li>
                <li>any additional terms applicable to providing the Services, except to the extent that such loss or damage as a direct result of our fraud or wilful misconduct.</li>
              </ul>
            </li>
          </ul>
        </p>
        <p>
          <b>17.4</b> (Third parties) We contract with a network of companies, providers, independent contractors and individuals to assist in the running of our tours
          (<b>Third Party Supplier</b>). Third Party Suppliers may also engage the services of local operators and sub-contractors.
          Although we take all reasonable steps to select reputable Third Party Suppliers, we cannot be responsible for their acts or omissions.
          You acknowledge that Third Party Suppliers operate in compliance with the applicable laws of the countries in which they operate and we do
          not warrant that any Third Party Supplier is in compliance with the laws of your country of residence or any other jurisdiction.
        </p>
        <p>We are not responsible for the acts and omissions, whether negligent or otherwise, of these Third Parties Suppliers. Any disputes between you and any third party, are to be resolved solely between you and that party.</p>
        <p>
          <b>17.5</b> (Vicarious liability) We shall not be held vicariously liable for the intentional or negligent acts of any persons not
          employed by us nor for any intentional or negligent acts of our employees committed while off duty or outside the course and scope of their employment.
        </p>
        <br />
        <h3>18. Optional activities</h3>
        <p>
          Optional activities not included in the trip price do not form part of the trip or this contract. You accept that any assistance,
          recommendations or advice given by your group leader or local representative in arranging optional activities
          (including before or after a trip) does not render us liable for them in any way.
          The contract for the provision of that activity will be between you and the activity provider.
        </p>
        <br />
        <h3>19. Feedback / Complaints</h3>
        <p>
          The tour guest or group leader agrees to bring any tour related complaints to Haka Tours as soon as possible
          in order to provide Haka Tours with the opportunity to address such complaint promptly and effectively.
          The tour guest agrees to inform the Tour manager or their tour advisor at the earliest opportunity.
          Haka Tours assumes no liability for complaints that are not properly brought to attention with sufficient notice
          to resolve or attempt to resolve guest complaints. Any complaint made after the completion of a tour must be received
          in writing to their tour advisor as soon as possible after the tour. The tour guest or group leader acknowledges and
          agrees that Haka Tours will not accept any liability for claims received after the tour.
        </p>
        <br />
        <h3>20. Accident, Compensation, Commission (ACC)</h3>
        <p>
          If you are injured whilst in New Zealand, ACC may be able to help with the cost of the treatment and support you need whilst you’re here.
          However, it is important to be aware that you cannot sue for personal injury – ACC replaces that right.
          Furthermore, ACC only covers treatment and rehabilitation costs whilst in New Zealand;
          it is not a replacement for travel insurance and does not cover illness, disrupted travel plans or emergency travel to get you back home.
        </p>
        <br />
        <h3>21. Severability</h3>
        <p>
          In the event that any term or condition contained in these Booking Conditions is unenforceable or void by operation of
          law or as being against public policy or for any other reason then such term or condition shall be deemed to be severed
          from this contract or amended accordingly only to such extent necessary to allow all
          remaining terms and conditions to survive and continue as binding.
        </p>
        <br />
        <h3>22. Applicable Law</h3>
        <p>
          The laws of New Zealand govern these Booking Terms and Conditions to the fullest extent allowable.
          Nothing in these Booking Conditions, including this clause 17, affects your rights as a consumer to rely on any applicable local laws.
        </p>
        <br />
        <h3>23. Promotional terms</h3>
        <p>
          We adhere to <a href="https://www.intrepidtravel.com/nz/ethical-marketing-guidelines" target="_blank" rel="noreferrer">Intrepid’s Ethical Marketing Guidelines</a> -
          which are a commitment to Intrepid’s vision of “changing the way we all see the world”, and to building a more
          inclusive travel industry overall. We also follow the <a href="https://www.asa.co.nz/codes/codes/advertising-standards-code/" target="_blank" rel="noreferrer">Advertising Standards Authority Code</a> to
          ensure that we carry out advertising is undertaken in a responsible manner.
        </p>
        <p>
          From time to time, we may run promotions and special offers (<b>Promotions</b>) on our website which are subject to
          both these Booking Conditions and any additional promotion-specific terms which are incorporated into these
          Booking Conditions by reference. You should ensure that you read the specific conditions that apply to each Promotion.
        </p>
        <p>
          Promotions are available for a limited time, as defined on our website. We reserve the right to cancel or change
          any Promotion at any time at our discretion. By purchasing a trip on a promotional basis, you agree and accept
          the terms that apply to the applicable Promotion. In the event of any inconsistencies between these
          Booking Conditions and the Promotional terms, the Promotional terms apply to your booking.
        </p>
      </div>
    </section>
  </Layout>
);

export default TermsPage;
